body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pw-ai-chat-bubble {
  background-color: #000;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}

.pw-typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.pw-typing .pw-dot {
  animation: pwTypingAnimation 1.8s infinite ease-in-out;
  background-color: #fafafa;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.pw-typing .pw-dot:nth-child(1) {
  animation-delay: 200ms;
}

.pw-typing .pw-dot:nth-child(2) {
  animation-delay: 300ms;
}

.pw-typing .pw-dot:nth-child(3) {
  animation-delay: 400ms;
}

.pw-typing .pw-dot:last-child {
  margin-right: 0;
}

@keyframes pwTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #a0a0a0;
  }
  28% {
    transform: translateY(-7px);
    background-color: #a0a0a0;
  }
  44% {
    transform: translateY(0px);
    background-color: #a0a0a0;
  }
}
