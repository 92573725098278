@tailwind base;
@tailwind components;
@tailwind utilities;

.pubtrawlr-markdown {
  strong {
    font-weight: 600 !important;
  }

  a {
    color: #778da9;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700 !important;
  }

  ul,
  ol {
    list-style-type: disc;
    padding-left: 1.2rem;
    margin-bottom: 1rem;

    li {
      font-size: 0.9rem;
      margin-bottom: 0.2rem;
    }
  }

  h1 {
    font-size: 1.3rem;
    margin-bottom: 1rem;

    @media screen and (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  h2 {
    font-size: 1.2rem;
    margin-bottom: 0.9rem;

    @media screen and (min-width: 768px) {
      font-size: 1.1rem;
    }
  }

  h3 {
    font-size: 1.1rem;
    margin-bottom: 0.8rem;

    @media screen and (min-width: 768px) {
      font-size: 1rem;
    }
  }

  h4 {
    font-size: 1rem;
    margin-bottom: 0.7rem;

    @media screen and (min-width: 768px) {
      font-size: 0.9rem;
    }
  }

  h5 {
    font-size: 0.9rem;
    margin-bottom: 0.6rem;

    @media screen and (min-width: 768px) {
      font-size: 0.8rem;
    }
  }

  h6 {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;

    @media screen and (min-width: 768px) {
      font-size: 0.7rem;
    }
  }

  p {
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
}
